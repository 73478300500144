import React from 'react';
import Footer from './footer';
import Header from './header';
import Splash from './splash';
import SEOHelmet from './seo-helmet';
import styles from './default.module.scss';

type Props = {
  headerChildren?: React.ReactNode;
  children: React.ReactNode;
  fullSplash: boolean;
  title?: string;
  description?: string;
};

const LayoutDefault: React.FC<Props> = ({
  children,
  headerChildren,
  fullSplash = true,
  title,
  description,
}: Props) => {
  return (
    <div>
      <Header />
      <main className={styles.main} role="main">
        <Splash pageId={'contact'} full={fullSplash} />
        <div className={styles.mainTop}>
          <div className={styles.headerContent}>{headerChildren}</div>
        </div>
        <div>{children}</div>
      </main>
      <Footer />
      <SEOHelmet title={title} description={description} />
    </div>
  );
};

export default LayoutDefault;
