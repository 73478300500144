import React from 'react';
import { Paragraph } from '@src/ui';

type Props = {
  city: string;
  near: string;
};

export const SecteurContentService: React.FC<Props> = ({ city, near }) => {
  return (
    <Paragraph title="Autres médecines complémentaires" hLevel={2}>
      En plus de l&apos;ostéopathie animale, le Dr Gaëlle Morat intervient{' '}
      {near} {city} pour vous offrir une gamme de soins complémentaires pour le
      bien-être de votre animal. Ces méthodes naturelles incluent la
      gemmothérapie, la micothérapie, les élixirs floraux, l&apos;homéopathie et
      la phytothérapie. Ces approches douces sont personnalisées selon les
      besoins spécifiques de chaque animal, dans une démarche globale et
      respectueuse.
    </Paragraph>
  );
};
