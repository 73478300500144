import React, { ReactNode } from 'react';
import styles from './paragraph.module.scss';
import { Title } from '../typography';

type Props = {
  title?: string;
  hLevel?: number;
  children: ReactNode;
};

export const Paragraph: React.FC<Props> = ({ title, hLevel, children }) => {
  return (
    <div className={styles.container}>
      {title && <Title hLevel={hLevel}>{title}</Title>}
      <div className={styles.content}>{children}</div>
    </div>
  );
};
