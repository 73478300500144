import React from 'react';
import LayoutDefault from '@src/layouts/layout-default';
import { PageContainer } from '@src/ui';
import { SecteurContentCities } from '@src/components/cities';
import { SecteurContentIntro } from './content-intro';
import { SecteurContentService } from './content-service';
import { SecteurContentContact } from './content-contact';

import styles from './page-secteur.module.scss';

type Props = {
  city: string;
  prefix?: string;
};

export const PageSecteur: React.FC<Props> = ({ city, prefix = 'à' }) => {
  let nearPrefix = 'près de';
  if (prefix === 'en') nearPrefix = 'en';
  if (prefix === 'au') nearPrefix = 'près du';

  return (
    <LayoutDefault
      fullSplash={false}
      title={`Rendez-vous vétérinaire ostéopathe pour chien, chat et NAC ${prefix} ${city}`}
      description={`Vétérinaire osthéopathe chiens, chats et NAC ${prefix} ${city}. Médecines complémentaires : ostéopathie animale, phytothérapie, homéopathie.`}
    >
      <PageContainer>
        <div className={styles.container}>
          <SecteurContentIntro city={city} prefix={prefix} near={nearPrefix} />
          <SecteurContentContact city={city} near={nearPrefix} />
          <SecteurContentService city={city} near={nearPrefix} />
          <SecteurContentCities
            title={`Disponible ${prefix} ${city} et ses environs`}
          />
        </div>
      </PageContainer>
    </LayoutDefault>
  );
};
