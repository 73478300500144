import React, { ReactNode } from 'react';
import { Link } from 'gatsby';
import styles from './button-link.module.scss';
import { trackEvent } from '@src/services/analytics';

type Props = {
  children: ReactNode;
  to: string;
};

export const ButtonLink: React.FC<Props> = ({ children, to }) => {
  return (
    <Link
      className={styles.link}
      to={to}
      onClick={() => trackEvent('Button', 'Click', to)}
    >
      {children}
    </Link>
  );
};
