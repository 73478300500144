import React from 'react';
import { Link } from 'gatsby';
import { Subtitle, Paragraph } from '@src/ui';
import styles from './cities.module.scss';

type Props = {
  title: string;
};

export const SecteurContentCities: React.FC<Props> = ({ title }) => {
  return (
    <Paragraph title={title} hLevel={2}>
      <div className={styles.container}>
        <div className={styles.department}>
          <Department name="Isère" slug="isere" />
          <div className={styles.cities}>
            <City name="Pontcharra" slug="pontcharra" />,{' '}
            <City name="Barraux" slug="barraux" />,{' '}
            <City name="Le Cheylas" slug="le-cheylas" />,{' '}
            <City name="Allevard" slug="allevard" />,{' '}
            <City name="Le Touvet" slug="le-touvet" />,{' '}
            <City name="Goncelin" slug="goncelin" />,{' '}
            <City name="Tencin" slug="tencin" />,{' '}
            <City name="Crolles" slug="crolles" />,{' '}
            <City name="Saint-Ismier" slug="saint-ismier" />,{' '}
            <City name="Grenoble" slug="grenoble" />
          </div>
        </div>
        <div className={styles.department}>
          <Department name="Savoie" slug="savoie" />
          <div className={styles.cities}>
            <City name="Porte-de-Savoie" slug="porte-de-savoie" />,{' '}
            <City name="Montmélian" slug="montmelian" />,{' '}
            <City name="La Rochette" slug="la-rochette" />,{' '}
            <City name="Chapareillan" slug="chapareillan" />,{' '}
            <City name="Challes-les-Eaux" slug="challes-les-eaux" />,{' '}
            <City name="La Ravoire" slug="la-ravoire" />,{' '}
            <City name="Saint-Alban-Leysse" slug="saint-alban-en-leysse" />,{' '}
            <City name="Barberaz" slug="barberaz" />,{' '}
            <City name="La Motte-Servolex" slug="la-motte-servolex" />,{' '}
            <City name="Le Bourget-du-Lac" slug="le-bourget-du-lac" />,{' '}
            <City name="Chambéry" slug="chambery" />,{' '}
            <City name="Aix-les-Bains" slug="aix-les-bains" />
          </div>
        </div>
      </div>
    </Paragraph>
  );
};

type LinkProps = {
  name: string;
  slug: string;
};

export const Department: React.FC<LinkProps> = ({ name, slug }: LinkProps) => {
  return (
    <Subtitle hLevel={3}>
      <Link
        className={styles.departmentLink}
        to={`/secteur/prendre-rendez-vous-veterinaire-osteopathe-${slug}`}
      >
        {name}
      </Link>
    </Subtitle>
  );
};

export const City: React.FC<LinkProps> = ({ name, slug }: LinkProps) => {
  return (
    <h4>
      <Link
        className={styles.cityLink}
        to={`/secteur/prendre-rendez-vous-veterinaire-osteopathe-${slug}`}
      >
        {name}
      </Link>
    </h4>
  );
};
