import React from 'react';
import { ButtonLink, Paragraph } from '@src/ui';

type Props = {
  city: string;
  prefix: string;
  near: string;
};

export const SecteurContentIntro: React.FC<Props> = ({
  city,
  prefix,
  near,
}) => {
  return (
    <Paragraph
      title={`Rendez-vous vétérinaire ostéopathe pour chien, chat et NAC ${prefix} ${city}`}
      hLevel={1}
    >
      <p>
        Votre vétérinaire ostéopathe <strong>Dr. Gaëlle Morat</strong> exerce{' '}
        {near} {city} pour offrir des soins adaptés pour votre chien, chat ou
        NAC. Son approche combine expertise vétérinaire et médecine
        complémentaire pour soulager les douleurs et améliorer le bien-être de
        votre animal. Que ce soit pour prévenir des troubles ou traiter une
        pathologie, le Dr. Gaëlle Morat propose des solutions sur mesure. Prenez
        rendez-vous pour un suivi personnalisé.
      </p>
      <ButtonLink to="/contact">Contacter le Dr Gaëlle Morat</ButtonLink>
    </Paragraph>
  );
};
