import React, { ReactNode } from 'react';
import styles from './page-container.module.scss';

type Props = {
  children: ReactNode;
};

export const PageContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
