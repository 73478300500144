import React from 'react';
import { Paragraph } from '@src/ui';

type Props = {
  city: string;
  near: string;
};

export const SecteurContentContact: React.FC<Props> = ({ city, near }) => {
  return (
    <Paragraph>
      Le Dr Gaëlle Morat est disponible {near} {city} pour répondre à vos
      questions, que ce soit pour obtenir des conseils ou pour discuter de la
      prise en charge ostéopathique de votre animal, n&apos;hésitez pas à
      contacter le cabinet.
    </Paragraph>
  );
};
